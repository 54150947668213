import { render, staticRenderFns } from "./DiyCompetition.vue?vue&type=template&id=41aeba75&scoped=true&"
import script from "./DiyCompetition.vue?vue&type=script&lang=ts&"
export * from "./DiyCompetition.vue?vue&type=script&lang=ts&"
import style0 from "./DiyCompetition.vue?vue&type=style&index=0&id=41aeba75&lang=scss&scoped=true&"
import style1 from "./DiyCompetition.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41aeba75",
  null
  
)

export default component.exports