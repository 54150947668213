var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"diy-competition px",class:{ mobile: _vm.isMobile, cn: _vm.isCN }},[_c('v-header',{attrs:{"title":_vm.$t('diyCompetition.name')}},[_c('a',{staticClass:"item",staticStyle:{"font-style":"italic","font-weight":"900"},attrs:{"href":_vm.url.diy,"target":"_blank"}},[_vm._v(" > DIY ")])]),_c('img',{staticClass:"image image01",attrs:{"src":_vm.isMobile
        ? _vm.isCN
          ? _vm.oss.home_diy_competition_mobile_cn
          : _vm.oss.home_diy_competition_mobile_en
        : _vm.isCN
        ? _vm.oss.home_diy_competition_cn
        : _vm.oss.home_diy_competition_en,"alt":"DIY COMPETITION"}}),_c('a',{attrs:{"href":_vm.url.diy_publish,"target":"_blank"}},[_c('img',{staticClass:"publish",attrs:{"src":_vm.isCN
          ? _vm.oss.diy_competition_contribute_cn
          : _vm.oss.diy_competition_contribute_en}})]),_c('img',{staticClass:"image line",attrs:{"src":_vm.oss.diy_competition_line,"alt":"DIY COMPETITION"}}),_c('div',{staticClass:"title title01",domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.title01'))}}),_c('div',{staticClass:"text text01",domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.text01'))}}),_c('div',{staticClass:"text example-text",domProps:{"innerHTML":_vm._s(_vm.title(_vm.$t('diyCompetition.example01')))}}),_c('div',{staticClass:"example",style:({
      backgroundImage: ("url(" + (_vm.oss.diy_competition_led_strip) + ")")
    }),attrs:{"alt":"DIY COMPETITION"}},[_c('img',{staticClass:"image",attrs:{"src":_vm.oss.diy_competition_example_01,"alt":"DIY COMPETITION"}})]),_c('div',{staticClass:"text example-text",domProps:{"innerHTML":_vm._s(_vm.title(_vm.$t('diyCompetition.example02')))}}),_c('div',{staticClass:"example",style:({
      backgroundImage: ("url(" + (_vm.oss.diy_competition_led_strip) + ")")
    })},[_c('img',{staticClass:"image",attrs:{"src":_vm.oss.diy_competition_example_02,"alt":"DIY COMPETITION"}})]),_c('div',{staticClass:"title title02",domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.title02'))}}),_c('div',{staticClass:"text text02",style:({
      backgroundImage: ("url(" + (_vm.oss.diy_competition_box) + ")")
    }),domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.text02'))}}),_c('div',{staticClass:"title title03",domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.title03'))}}),_c('div',{staticClass:"text text03",style:({
      backgroundImage: ("url(" + (_vm.oss.diy_competition_square) + ")")
    }),domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.text03'))}}),_c('div',{staticClass:"text text04",style:({
      backgroundImage: ("url(" + (_vm.oss.diy_competition_square) + ")")
    }),domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.text04'))}}),_c('img',{staticClass:"image form",attrs:{"src":_vm.isCN ? _vm.oss.diy_competition_form_cn : _vm.oss.diy_competition_form_en,"alt":"DIY COMPETITION"}}),_c('div',{staticClass:"title title04",domProps:{"innerHTML":_vm._s(_vm.title(_vm.$t('diyCompetition.title04')))}}),_c('div',{staticClass:"text text05",domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.text05'))}}),_c('v-video',{staticClass:"image image04",attrs:{"src":_vm.isCN ? _vm.oss.diy_tutorial_cyberboard_cn : _vm.oss.diy_tutorial_cyberboard_en}}),_c('div',{staticClass:"title title05",domProps:{"innerHTML":_vm._s(_vm.title(_vm.$t('diyCompetition.title05')))}}),_c('img',{staticClass:"arrow-left",attrs:{"src":_vm.oss.diy_competition_arrow,"alt":"DIY COMPETITION"}}),_c('div',{staticClass:"text text06",domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.text06'))}}),_c('img',{staticClass:"arrow-right",attrs:{"src":_vm.oss.diy_competition_arrow,"alt":"DIY COMPETITION"}}),_c('div',{staticClass:"title title06",domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.title06'))}}),_c('div',{staticClass:"timeline",style:({
      backgroundImage: ("url(" + (_vm.oss.diy_competition_timeline) + ")")
    })},_vm._l((4),function(i){return _c('div',{key:i,staticClass:"time",style:({
        backgroundImage: ("url(" + (_vm.oss.diy_competition_square) + ")")
      }),domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.time0' + i))}})}),0),_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.$t('diyCompetition.text07'))}}),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }